/* eslint-disable no-lone-blocks */
<script>
import '@/utils/directives'
export default {
  data () {
    return {
      websocket: null,
      timer: 0,
      reciveMsg: null,
      paramsRouteId: null,
      paramsRouteliveId: null,
      sendMsgInp: '',
      accept: '',
      userName: localStorage.getItem('username'),
      arrmsg: [],
      mainmsg: [],
      pullUrl: '',

      bl: true
    }
  },
  mounted () {
    this.paramsRouteId = this.$route.query.id
    this.paramsRouteliveId = this.$route.query.liveId
    this.createWebSocket(process.env.VUE_APP_WEBSOKECT_URL)
  },
  render () {
    return (
      <d2-container>
        <div class="imItem_wrapper" ref="wrapperIM" id="wrapperIM">
          <div class="flex_wrap_item">{this._renderContainerTalkMsg()}</div>
        </div>
        <div class="msgEnter">
          <div class="func_column_flex">
            <div class="marT">
              <i class="lemon-icon-image"></i>
            </div>
            <input
              style="display:none"
              type="file"
              multiple="multiple"
              ref="fileInput"
              accept={this.accept}
              on-change={this._handleChangeFile}
            />
          </div>
          <el-input
            class="inp"
            type="textarea"
            rows="4"
            auto-complete="off"
            v-model={this.sendMsgInp}
            placeholder="请在此输入内容"
            on-input={this.handelInpContent}
          ></el-input>
          <el-button
            class="position_btn"
            on-click={this.handelSendBtn}
            type="default"
          >
            发送
          </el-button>
        </div>
      </d2-container>
    )
  },
  methods: {
    _renderContainerTalkMsg () {
      const node = []
      this.arrmsg.map((item, index) => {
        item.uid === localStorage.getItem('uuid')
          ? node.push(
            <div
              class="imItem_wrapper1"
              style={{ top: index * 80 + 20 + 'px' }}
            >
              <div class="flex_item1">
                <div class="user_top">
                  <div>
                    <span>{item.name}</span>
                  </div>
                  <div class="tip right">{item.msg}</div>
                </div>
                <div class="avatar_pic">
                  <img src={item.avatar} width="100%" height="100%" />
                </div>
              </div>
            </div>
          )
          : node.push(
            <div
              class="imItem_wrapper2"
              style={{ top: index * 80 + 20 + 'px' }}
            >
              <div class="flex_item">
                <div class="avatar_pic">
                  <img src={item.avatar} width="100%" height="100%" />
                </div>
                <div class="user_top">
                  <div>
                    <span>{item.name}</span>
                  </div>
                  <div class="box-tooltip">{item.msg}</div>
                </div>
              </div>
            </div>
          )
      })
      return node
    },
    // 表情包选择
    handleEmoj () {},
    // 发送文件
    handleSendFile () {},
    // 输入文字
    handelInpContent (e) {
      this.sendMsgInp = e
    },
    _handleChangeFile (e) {
      const { fileInput } = this.$refs
      Array.from(fileInput.files).forEach((file) => {
        this._handleUpload(file)
      })
      fileInput.value = ''
    },
    _handleUpload (file) {},
    generateUUID () {
      var d = new Date().getTime()
      if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now() // use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0
          d = Math.floor(d / 16)
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
        }
      )
      return uuid
    },
    reconnect (url) {
      setTimeout(() => {
        // 没连接上会一直重连，设置延迟避免请求过多
        this.createWebSocket(url)
      }, 2000)
    },
    // webscoket 初始化
    createWebSocket (url) {
      try {
        if ('WebSocket' in window) {
          this.websocket = new WebSocket(url)
        }
        this.initEventHandle()
      } catch (e) {
        this.reconnect(process.env.VUE_APP_WEBSOKECT_URL)
      }
    },
    // 初始化事件函数
    initEventHandle () {
      this.websocket.onmessage = this.websocketonmessage // 客户端接收服务端数据时触发
      this.websocket.onopen = this.websocketonopen // 连接建立时触发
      this.websocket.onclose = () => {
        if (this.bl) {
          this.reconnect(process.env.VUE_APP_WEBSOKECT_URL)
        }
      }
      this.websocket.onerror = () => {
        this.reconnect(process.env.VUE_APP_WEBSOKECT_URL)
      }
    },
    // 连接建立
    websocketonopen () {
      setTimeout(() => {
        this.doSend()
      }, 300)
    },
    // websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend () {
      const data = {
        id: this.generateUUID(),
        tm: Date.parse(new Date()) * 1000, // 当前时间戳,
        msgType: 'ENTER_LIVE',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        sign: '',
        liveId: this.paramsRouteliveId,
        vwUid: localStorage.getItem('uuid')
      }
      this.websocket.send(JSON.stringify(data))
    },
    // 发送数据
    websocketsend () {
      var time = Date.parse(new Date()) * 1000 // 当前时间戳
      let data = {
        id: this.generateUUID(),
        tm: time,
        msgType: 'IM',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        sign: '',
        role: 'COMP_COUNSELOR'
      }
      data = JSON.stringify(data) // websocket只能传字符串数据
      this.websocket.send(data) // 发送数据，传什么数据由实际需求决定
    },
    // 发送按钮点击事件
    handelSendBtn () {
      const data = this._createMessage()
      this.arrmsg.push({
        msg: this.sendMsgInp,
        name: localStorage.getItem('username'),
        avatar: localStorage.getItem('avatar'),
        uid: localStorage.getItem('uuid') + ''
      })
      this.sendMsgInp = ''
      this.websocket.send(JSON.stringify(data)) // 发送数据，传什么数据由实际需求决定
    },
    // 发送一条消息
    _createMessage () {
      return {
        id: this.generateUUID(),
        tm: Date.parse(new Date()) * 1000, // 当前时间戳,
        msgType: 'SEND_LIVE_MSG',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        senderUid: localStorage.getItem('uuid'),
        liveId: this.paramsRouteliveId,
        contentType: 'TEXT',
        format: '',
        content: this.sendMsgInp,
        role: 'COMP_COUNSELOR'
      }
    },
    // 数据接收
    websocketonmessage (e) {
      var res = JSON.parse(e.data)
      this.reciveMsg = res
      const obj = {}
      if (this.reciveMsg.msgType === 'WHO_ARE_YOU') {
        this.clientId = res.clientId
        this.websocketsend()
      } else if (
        this.reciveMsg.msgType === 'RECEIVE_LIVE_MSG' &&
        res.senderUid !== localStorage.getItem('uuid')
      ) {
        obj.name = res.senderName
        obj.avatar = res.senderAvatar
        obj.msg = res.content
        obj.uid = res.senderUid + ''
        this.arrmsg.push(obj)
      }
    }
  },
  // vue生命周期销毁后
  destroyed () {
    this.bl = false
    this.websocket.close() // 离开路由之后断开websocket连接
    clearInterval(this.timer) // 把定时器清掉
  }
}
</script>
<style lang="scss" scoped>
.imItem_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 75%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f3f3f3;
  padding: 0 0;
}
.flex_wrap_item {
  flex: 1;
  height: 100%;
  position: relative;
  .imItem_wrapper1 {
    position: absolute;
    right: 30px;
  }
  .imItem_wrapper2 {
    position: absolute;
    left: 30px;
  }
}
.flex_item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 30px;
  margin-bottom: 30px;
  .avatar_pic {
    width: 60px;
    height: 60px;
    background: #eee;
    border: 1px solid #999;
    margin-right: 10px;
  }
  .user_top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .box-tooltip {
      position: relative;
      padding: 10px 40px;
      min-width: 100px;
      max-width: 200px;
      text-align: justify;
      margin-top: 5px;
      background: #ffffff;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      box-shadow: 1px 2px 4px #e0e0e0;
      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        border-top: 6px solid transparent;
        border-right: 6px solid #ffffff;
        border-bottom: 6px solid transparent;
      }
    }
  }
}
.flex_item1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 30px;
  margin-bottom: 30px;
  .avatar_pic {
    width: 60px;
    height: 60px;
    background: #eee;
    border: 1px solid #999;
    margin-right: 10px;
  }
  .user_top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}
.msgEnter {
  width: 99.8%;
  height: 193px;
  background: #fff;
  border: 1px solid #999;
  position: relative;
  .position_btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .inp {
    width: 80%;
    margin-top: 10px;
    margin-left: 10px;
  }
  .func_column_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .marT {
      margin-top: 10px;
      margin-left: 10px;
      .lemon-icon-emoji {
        font-size: 30px;
      }
      .lemon-icon-folder {
        font-size: 30px;
      }
      .lemon-icon-image {
        font-size: 30px;
      }
    }
  }
}
.tip {
  position: relative;
  padding: 10px 40px;
  min-width: 100px;
  max-width: 200px;
  text-align: justify;
  margin-top: 5px;
  background: green;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 1px 2px 4px #e0e0e0;
  margin-right: 20px;
}
.tip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid;
}
.right:after {
  border-right-color: transparent;
  left: 100%;
  top: 50%;
  margin-top: -7px;
  border-top: 6px solid transparent;
  border-left: 6px solid green;
  border-bottom: 6px solid transparent;
}
.videoSrc {
  width: 300px;
  height: 100%;
  background: green;
}
</style>
